:root[webchat-theme='theme1'] {
  --bubble-outgoing-file-width: 186px;
  --bubble-file-align-items: center;
  --bubble-file-justify-content: start;
  --bubble-file-gap: 12px;
  --bubble-file-margin: 0;
  --bottom-input-div-width: 100%;
  --bottom-input-div-gap: 8px;
  --bubble-file-left-gap: 12px;
  --bubble-file-left-align-items: center;
  --uploaded-file-padding: 8px 12px;
  --uploaded-file-width: 186px;
  --uploaded-file-height: 44px;
  --uploaded-file-flex: none;
  --uploaded-file-back-color: var(--brand-primary-3-100);
  --bubble-file-close-icon-width: 16px;
  --bubble-file-close-icon-height: 16px;
  --bubble-file-close-icon-padding: 4px;
  --bubble-file-close-icon-background: url('./../../icons/file-delete.svg') no-repeat center center;
  --bubble-file-close-icon-background-size: 16px;
  --bubble-file-icon-content-back-color: var(--white-shades-16);
  --bubble-incoming-file-icon-content-back-color: var(--brand-primary-3-100);
  --bubble-file-icon-content-width: 44px;
  --bubble-file-icon-content-height: 44px;
  --bubble-file-icon: url('./../../icons/file-pdf.svg') no-repeat center center;
  --bubble-file-icon-width: 24px;
  --bubble-file-icon-height: 24px;
  --bubble-file-icon-content-radius: 50%;
  --bubble-file-content-title-font: var(--font-small-medium);
  --bubble-file-content-title-color: var(--pure-white);
  --bubble-incoming-file-content-title-color: var(--bubble-incoming-text-color);
  --bubble-file-content-info-font: var(--font-small-regular);
  --bubble-file-content-info-color: var(--white-shades-48);
  --bubble-incoming-file-content-info-color: var(--text-secondary);
  --line-width: 100%;
  --line-height: 1px;
  --line-back-color: var(--white-shades-16);
  --line-incoming-back-color: var(--dark-shades-4);
  --line-margin: 8px 0;
}
