:root {
  --pure-white: #fff;
  --pure-black: #000;

  --text-primary: #fff;
  --text-secondary: #909099;
  --text-dark: #414141;
  --text-brand: #9747ff;
  --text-place-holder: rgb(144, 144, 153);

  --white-shades-16: rgba(255, 255, 255, 0.16);
  --white-shades-48: rgba(255, 255, 255, 0.48);
  --white-shades-64: rgba(255, 255, 255, 0.64);
}
:root {
  --icon-light: #fff;
  --icon-dark: #414141;
  --brand-primary-3-100: #9747ff;
  --brand-primary-3-200: #8425fe;
  --dark-shades-4: rgba(0, 0, 0, 0.04);
  --dark-shades-48: rgba(0, 0, 0, 0.48);
  --dark-shades-80: rgba(0, 0, 0, 0.8);
  --dark-shades-8: rgba(0, 0, 0, 0.08);
  --theme-1-back-color: linear-gradient(180deg, #fff 42.9%, #f7f2fe 85.54%);
  --border-primary: #e2e2e4;
  --shadow-100: rgba(65, 65, 65, 0.12);
  --shadow-200: rgba(65, 65, 65, 0.08);
}
