:root[webchat-theme='theme1'] {
  --confirm-panel-width: 331px;
  --confirm-panel-height: 212px;
  --confirm-panel-border: none;
  --confirm-panel-radius: 16px;
  --confirm-panel-back-color: var(--dark-shades-4);
  --confirm-panel-wrapper-padding: 24px 32px;
  --confirm-panel-margin: 0 auto;
  --confirm-panel-text-align: center;
  --confirm-panel-position: absolute;
  --confirm-panel-position-left: 0;
  --confirm-panel-position-right: 0;
  --confirm-panel-position-top: 50%;
  --confirm-panel-position-bottom: 0;
  --confirm-panel-transform: translateY(-50%);
  --confirm-panel-z-index: 100;
  --confirm-panel-align-items: center;
  --confirm-panel-justify-content: center;
  --confirm-panel-wrapper-gap: 16px;
  --confirm-panel-wrapper-align-items: center;
  --confirm-panel-wrapper-icon-visibility: flex;
  --confirm-panel-wrapper-icon-width: 48px;
  --confirm-panel-wrapper-icon-height: 48px;
  --confirm-panel-wrapper-icon: url('./../../icons/confirm-panel-icon.svg');
  --confirm-panel-wrapper-icon-back-size: contain;
  --confirm-panel-wrapper-icon-margin: 0;
  --confirm-panel-wrapper-title-font: var(--font-big-medium);
  --confirm-panel-wrapper-title-color: var(--pure-black);
  --confirm-panel-wrapper-buttons-justify-content: center;
  --confirm-panel-wrapper-buttons-gap: 8px;
  --confirm-panel-wrapper-buttons-font: var(--font-normal-medium);
  --confirm-panel-wrapper-buttons-radius: 8px;
  --confirm-panel-wrapper-buttons-reject-color: var(--text-brand);
  --confirm-panel-wrapper-buttons-accept-color: var(--pure-white);
  --confirm-panel-wrapper-buttons-width: 96px;
  --confirm-panel-wrapper-buttons-height: 36px;
  --confirm-panel-button-transition: background-color 0.5s ease-in-out,
    outline-color 0.5s ease-in-out;
  --button-accept-back-color: var(--brand-primary-3-100);
  --button-accept-outline: none;
  --button-reject-back-color: var(--pure-white);
  --button-reject-outline: 2px solid var(--border-primary);
  --button-accept-outline-hover: none;
  --button-accept-back-color-hover: var(--brand-primary-3-200);
  --button-reject-outline-hover: 2px solid var(--brand-primary-3-100);
  --button-reject-back-color-hover: var(--pure-white);
}
